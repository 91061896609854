<template>
  <div>
    <div class="uk-flex uk-flex-between" v-if="!isLoading">
      <div>
        <div class="uk-text-large uk-text-bold" v-if="quote.user.business">
          {{ quote.user.business.name }}
        </div>
        <div>{{ quote.user.first_name }} {{ quote.user.last_name }}</div>
      </div>

      <div class="uk-text-right">
        <div class="uk-text-large">
          Cotización
          <span class="uk-text-bold">{{ quote.code }}</span>
        </div>
        <div>Fecha de cotización: {{ formatDate(quote.created_at) }}</div>
      </div>
    </div>

    <hr class="uk-divider-icon" />

    <div class="uk-margin-top uk-flex uk-flex-between">
      <div class="uk-text-large">Productos en cotización</div>
      <div>
        <button
          class="uk-button uk-button-primary uk-border-rounded"
          :disabled="isLoading"
          @click="regenerateQuote"
        >
          <span v-if="isLoading" uk-spinner key="loader"></span>
          <span v-else
            >Generar nueva cotización con información actualizada</span
          >
        </button>
      </div>
    </div>
    <table class="uk-table uk-table-middle uk-table-hover uk-table-divider">
      <thead>
        <tr>
          <th>Selecciones del cliente</th>
          <th>Información de producto</th>
          <th>Constantes utilizadas</th>
          <th>Precio unitario</th>
          <th>Precio total</th>
        </tr>
      </thead>
      <tbody v-if="!isLoading">
        <tr v-for="quotable in quote.quotables" :key="quotable.id">
          <td>
            <div>Cantidad</div>
            <input
              class="uk-input uk-border-rounded uk-text-center uk-width-small"
              type="text"
              name="name"
              v-model="quotable.quantity"
            />
            <div class="uk-margin-small-top">Rostros de Impresión</div>
            <select
              name="Colores de Impresión"
              v-model="quotable.faces"
              class="uk-select uk-border-rounded uk-width-small"
            >
              <option>1</option>
              <option>2</option>
            </select>
            <div class="uk-margin-top">Colores de Impresión</div>
            <select
              name="Colores de Impresión"
              v-model="quotable.colors"
              class="uk-select uk-border-rounded uk-width-small"
            >
              <option>1</option>
              <option>2</option>
            </select>
          </td>
          <td>
            <div class="uk-text-center">
              <img
                width="150px"
                :src="
                  quotable.main_image
                    ? quotable.main_image
                    : api + '/storefronts/' + storefrontId + '/logo'
                "
                alt
              />
            </div>
            <div class="uk-margin-top">
              Proveedor:
              <span v-if="quotable.quotable_type === 'App\\Models\\Product'">
                {{ quotable.quotable.provider.name }}
              </span>
              <span
                v-if="
                  quotable.quotable_type === 'App\\Models\\ProductVariation'
                "
              >
                {{ quotable.quotable.product.provider.name }}
              </span>
            </div>
            <div>
              Código de proveedor:
              <span v-if="quotable.quotable_type === 'App\\Models\\Product'">
                {{ quotable.quotable.provider_code }}
              </span>
              <span
                v-if="
                  quotable.quotable_type === 'App\\Models\\ProductVariation'
                "
              >
                {{ quotable.quotable.product.provider_code }}
              </span>
            </div>
          </td>
          <td>
            <ul uk-accordion>
              <li>
                <a class="uk-accordion-title" href="#">Ver constantes</a>
                <div class="uk-accordion-content">
                  <div
                    class="uk-margin"
                    v-for="constantValue in quotable.constantValues"
                    :key="constantValue.id"
                  >
                    <div>{{ constantValue.name }}</div>
                    <input
                      class="uk-input uk-border-rounded"
                      type="text"
                      name="name"
                      v-model="constantValue.value"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </td>
          <td>
            {{ toCurrency(quotable.price) }}
          </td>
          <td>
            {{ toCurrency(quotable.price * quotable.quantity) }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            Total:
            {{
              toCurrency(
                quote.quotables.reduce(
                  (accum, quotable) =>
                    accum + quotable.price * quotable.quantity,
                  0
                )
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="isLoading" class="uk-text-center">
      <div uk-spinner key="loader2"></div>
    </div>

    <div class="uk-text-center">
      <button
        class="uk-button uk-button-primary uk-border-rounded"
        :disabled="isLoading"
        @click="regenerateQuote"
      >
        <span v-if="isLoading" uk-spinner key="loader3"></span>
        <span v-else>Generar nueva cotización con información actualizada</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuoteShow",

  data() {
    return {
      quote: null,
      isLoading: true,
      timer: null,
    };
  },

  mounted() {
    this.isLoading = true;
    this.axios
      .get("/quotes/review/" + this.$route.params.id)
      .then(({ data: { quote } }) => {
        this.quote = quote;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    regenerateQuote() {
      this.isLoading = true;
      this.axios
        .post("/quotes/regenerate", this.quote)
        .then(() => {})
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  watch: {
    quote: {
      deep: true,
      handler() {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.isLoading = true;
          this.axios
            .post("/quotes/review/preview", this.quote)
            .then(({ data: { quotables } }) => {
              // this.quote.quotables = quotables;
              this.quote.quotables = this.quote.quotables.map(
                (quotable, index) => {
                  quotable.price = quotables[index].price;
                  return quotable;
                }
              );
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              this.isLoading = false;
              clearTimeout(this.timer);
            });
        }, 1000);
      },
    },
  },
};
</script>
